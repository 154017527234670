import validate from "/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.3.1_ioredis@5.6.0_less@4.2._8bcfaae2419501d9f16e4f9a37f9bfee/node_modules/nuxt/dist/pages/runtime/validate.js";
import craft_45redirect_45global from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/middleware/craft-redirect.global.js";
import no_45cache_45404_45global from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/middleware/no-cache-404.global.js";
import trailing_45slash_45global from "/home/runner/work/coassemble-author/coassemble-author/frontend/src/middleware/trailing-slash.global.js";
export const globalMiddleware = [
  validate,
  craft_45redirect_45global,
  no_45cache_45404_45global,
  trailing_45slash_45global
]
export const namedMiddleware = {}